


































































































































































import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { hsLoading } from '@/components';
import studentService from '@/sparkmembers/services/student';
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import MemberActions from './MemberActions.vue';
import MButton from '@/shared/components/MButton.vue';
import Confirm from '@/shared/mixins/Confirm';
import ToastHelper from '@/shared/helpers/toast';
import { Member, Tag } from '@/types/members';
import { BDropdown } from 'bootstrap-vue';

const MemberModule = namespace('member');

@Component({
  components: {
    hsLoading,
    MemberActions,
    MButton,
    Timeline: () => import('@/sparkmembers/views/MemberDetail/components/Tab/Resume/components/Timeline/index.vue'),
    MemberProfilePicture: () => import('@/components/MemberProfilePicture.vue'),
  },
  filters: {
    date(value: any) {
      return dayjs(value).format('DD/MM/YYYY');
    },
    time(value: any) {
      return dayjs(value).format('HH:mm');
    },
    currency(value: any) {
      return GeneralHelper.currency(value / 100, false);
    },
  },
})
export default class MemberSidebar extends Mixins(Confirm) {
  @MemberModule.State selectedMember!: any;

  @MemberModule.Action updateMember!: (payload: Member) => any;

  @Ref() dropdownTags!: BDropdown;

  isLoading: boolean = true;
  loadingAnalitycs: boolean = true;
  dataAnalitycs: any = {};
  totalSpentConfirmedOrders: number = 0;
  qtdActiveProducts: number = 0;
  activeProducts: any = [];
  overflow: string = 'hidden';
  showConfirmBox!: any;
  prefixLang: string = 'sparkmembers.members-list.tags-modal';
  hideAmountTemporarily: boolean = false;

  @Watch('selectedMember')
  onChangeMember() {
    this.reloadData();
  }

  created() {
    this.reloadData();
  }

  async reloadData() {
    this.getActiveProducts();
    await this.loadAnalitycs();
  }

  get fullname() {
    return `${this.selectedMember.first_name} ${
      this.selectedMember.last_name ? this.selectedMember.last_name : ''
    }`.trim();
  }

  get isProfilePictureDefault() {
    return this.selectedMember.cover_image_url.includes('/default.jpeg');
  }

  get tags() {
    return this.selectedMember.categories;
  }

  get hasAnalitycs() {
    return Boolean(this.dataAnalitycs.lessons_progresses.length || this.dataAnalitycs.orders.length);
  }

  getBadgeVariant(status: any) {
    const types = ['active', 'alumnus', 'lead'];
    const variants = ['success', 'cherry', 'secondary'];
    return variants[types.indexOf(status)];
  }

  getActiveProducts() {
    this.qtdActiveProducts = 0;
    this.activeProducts = [];
    this.selectedMember.registrations[0].enrollments.forEach((enroll: any) => {
      if (enroll.status === 'active') {
        this.qtdActiveProducts += 1;
        this.activeProducts.push(enroll.school_product.title);
      }
    });
  }

  async loadAnalitycs() {
    try {
      this.loadingAnalitycs = true;
      this.overflow = 'hidden';
      this.totalSpentConfirmedOrders = 0;

      const res: any = await studentService.analitycs(this.selectedMember.id);
      this.dataAnalitycs = res;
      this.getTotalSpentConfirmedOrders(res.orders);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.members-list.members-sidebar.toast.analitycs-error'));
    } finally {
      this.loadingAnalitycs = false;
      this.overflow = 'auto';
    }
  }

  getTotalSpentConfirmedOrders(orders: any[]) {
    orders.map((order: any) => {
      if (order.status === 'confirmed') {
        this.totalSpentConfirmedOrders += order.amount_to_pay;
      }
    });
  }

  async confirmRemoveTagModal(tag: Tag) {
    return await this.showConfirmBox({
      contentTitle: this.$t(`${this.prefixLang}.delete-tag-modal.title`),
      contentDescription: this.$t(`${this.prefixLang}.delete-tag-modal.subtitle-member`, {
        tag: tag.name,
        member: this.fullname,
      }),
      okTitle: this.$t(`${this.prefixLang}.delete-tag-modal.buttons.ok`),
      cancelTitle: this.$t(`${this.prefixLang}.delete-tag-modal.buttons.cancel`),
      variant: 'cherry',
      icon: 'trash-alt',
    });
  }

  async removeTag(tag: Tag) {
    const confirm = await this.confirmRemoveTagModal(tag);

    if (!confirm) return;

    try {
      const payload = {
        ...this.selectedMember,
        categories: this.selectedMember.categories.filter(category => category.id !== tag.id),
        category_ids: this.selectedMember.categories.reduce(
          (ids, category) => (category.id !== tag.id && ids.push(category.id), ids),
          []
        ),
      };

      await this.updateMember(payload);

      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.delete-tag-success`));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.delete-tag-error`));
    }
  }
}
